import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Seo from "../components/Seo/Seo";

const VitaWrapper = styled.div`
  margin: 0 0 4em 0;
`;

const VitaPage = ({ data }) => {
  const text = data.contentfulPage.text;
  return (
    <VitaWrapper>
      <Seo title="Vita" />
      <div
        dangerouslySetInnerHTML={{ __html: text.childMarkdownRemark.html }}
      />
    </VitaWrapper>
  );
};

export default VitaPage;

export const query = graphql`
  query VitaQuery {
    contentfulPage(slug: { eq: "vita" }) {
      id
      title
      slug
      text {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
